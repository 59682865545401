

export interface EnvironmentConfig {
    userApiPath: string;
    applicationApiPath: string;
    chatApiPath: string;
}

export function setupConfig(config: EnvironmentConfig) {
    if (!window) {
        return;
    }
    const win = window as any;
    const roundZero = win.roundZero;
    if (roundZero && roundZero.config &&
        roundZero.config.constructor.name !== 'Object') {
        console.error('RoundZero config was already initialized');
        return;
    }
    win.roundZero = win.roundZero || {};
    win.roundZero.config = {
        ...win.roundZero.config,
        ...config
    };
    return win.roundZero.config;
}

export class EnvironmentConfigService {
        private static instance: EnvironmentConfigService;
        private m: Map<keyof EnvironmentConfig, any>;
        private constructor() {
            this.init();
        }
        static getInstance() {
            if (!EnvironmentConfigService.instance) 
                EnvironmentConfigService.instance = new EnvironmentConfigService();
            return EnvironmentConfigService.instance;
        }
        private init() {
            if (!window) {
                return;
            }
            const win = window as any;
            const roundZero = win.roundZero;
            this.m = new Map<keyof EnvironmentConfig, any>(Object.entries(roundZero.config) as any);
        }
        get(key: keyof EnvironmentConfig, fallback?: any): any {
            const value = this.m.get(key);
            return (value !== undefined) ? value : fallback;
        }
    }